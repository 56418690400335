.filter_container {
  position: static;
  // width: 484px;
  height: 120px;
  left: 0px;
  top: 400px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
}
.filter_inner_container {
  padding: 1rem;
}
.filter_text_container {
  display: flex;
  justify-content: space-between;
}
.filter_filterby {
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
}

.filter_filterby:hover {
  color: #d9001d;
}

.filter_close_all {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  margin-top: -0.5rem !important;
  margin-right: -0.5rem !important;
}
.filter_select_container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.filter_status_container {
  width: 47%;
}
.filter_department_container {
  width: 47%;
}
.active_container {
  margin: 0.8rem;
  width: 246px;
  height: 120px;
  background: #ffffff;
  border-radius: 4px;
}
.active_select_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.active_select_inner_container {
  width: 100%;
}
.filter_container {
  position: static;
  width: 484px;
  height: 120px;
  left: 0px;
  top: 40px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
}
.filter_inner_container {
  padding: 1rem;
}
.filter_text_container {
  display: flex;
  justify-content: space-between;
}
.filter_filterby {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
}
.filter_close_all {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  margin-top: -0.5rem !important;
  margin-right: -0.5rem !important;
}
.filter_select_container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.filter_status_container {
  width: 70%;
}
.filter_department_container {
  width: 47%;
}
.active_container {
  margin: 0.8rem;
  width: 246px;
  height: 120px;
  background: #ffffff;
  border-radius: 4px;
}
.active_select_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.active_select_inner_container {
  width: 100%;
}
