.top_bar {
  display: flex;
  justify-content: space-between;
  position: fixed;
  // width: 95%;
  align-items: center;
  width: 100%;
  padding: 1.5rem 0rem;
  background-color: #ffffff;
}
.outer_box {
  overflow-y: scroll;
  height: 100vh;
}
.outer_box_small {
  overflow: scroll;
}
.logo_box {
  display: flex;
  margin-left: 10px;
}
.logo_container {
  margin-top: 3px;
  margin-right: 0.4rem;
}
.logo_img {
  width: 100px !important;
}
.logo_img_small {
  width: 80px !important;
}
.icon_text {
  margin-top: 5px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 15.85px;
  line-height: 122.69%;
}
.sign_in {
  font-style: normal;
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 20px !important;
  text-align: center;
  padding-right: 10px;
  color: #d9001d !important;
}
.landing_screen {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 4rem 0rem 0rem 0rem;
}
.content_box {
  width: 50%;
}
.nfx_box {
  font-weight: 800;
  font-size: 52px;
  line-height: 160%;
  color: #d9001d;
}
.main_title {
  font-weight: 800;
  font-size: 52px;
  line-height: 160%;
  color: #161616;
}
.sub_title {
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  text-align: left;
  color: #161616;
}
.overview_container {
  width: 100%;
}
.overview_img {
  max-width: 115rem;
}
.introduction_box {
  background: #f8f8f8;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 3rem 0rem;
}
.intro_content_box {
  width: 40%;
}
.ques {
  font-weight: 700;
  font-size: 32px;
  line-height: 120%;
  font-family: Mulish;
  color: #041630;
}
.ans {
  font-weight: 400;
  font-family: Mulish;
  font-size: 15px;
  line-height: 30px;
  color: #041630;
  margin-top: 1rem;
}
.company_img_container {
  width: 30%;
  margin-left: 3rem;
}
.company_img {
  max-width: 50rem;
}
.about_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 3rem 0rem;
}
.about_img_container {
  width: 50%;
  margin-left: 3rem;
  margin-right: 3rem;
}
.about_img {
  max-width: 50rem;
}
.ques_box {
  font-weight: 700;
  font-size: 32px;
  line-height: 120%;
  font-family: Mulish;
  color: #041630;
}
.ans_box {
  margin-top: 1rem;
  font-weight: 400;
  font-size: 15px;
  line-height: 30px;
  color: #041630;
  font-family: Mulish;
}
.btn_container {
  margin-top: 1rem;
}
.social_media_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
  gap: 24px;
  background: #363636;
  width: 100%;
}
.follow_box {
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  color: #ffffff;
  text-align: center;
  font-family: Mulish;
}
.social_icon {
  margin-top: 1rem;
  display: flex;
  justify-content: space-around;
}
.inner_box {
  width: 30%;
}
.social_container {
  height: 3rem;
  width: 3rem;
}
.social_img {
  height: 3rem;
  width: 3rem;
}
@media screen and (max-width: 566px) {
  .login_box {
    display: none;
  }
  .landing_screen {
    flex-direction: column;
  }
  .overview_container {
    width: 100%;
    // padding-left: 2rem;
  }
  .introduction_box {
    padding: 3rem 0rem;
  }
  .ques {
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    text-align: center;
  }
  .ans {
    font-weight: 400;
    font-size: 14px;
    line-height: 180%;
    text-align: center;
    font-family: Mulish;

    padding: 1rem;
    margin-top: 1rem;
  }
  .follow_box {
    font-size: 22px;
  }
  .content_box {
    width: 100%;
    padding: 1rem 0rem;
  }
  .inner_box {
    width: 50%;
  }
  .overview_container {
    margin-top: 4rem;
  }
  .main_title {
    font-weight: 800;
    font-size: 32px;
    line-height: 56px;
    text-align: center;
  }
  .nfx_box {
    font-weight: 800;
    font-size: 32px;
    line-height: 56px;
    text-align: center;
  }
  .sub_title {
    font-weight: 600;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
  }
  .top_bar {
    justify-content: center;
    align-items: center;
    padding: 2rem 0rem;
    z-index: 1000;
  }
  .intro_content_box {
    width: 100%;
  }
  .company_img_container {
    width: 100%;
    margin-left: 0px;
  }
  .about_container {
    padding: 3rem 0rem;
    flex-direction: column;
  }
  .ques_box {
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    text-align: center;
  }
  .ans_box {
    font-weight: 400;
    font-size: 14px;
    line-height: 180%;
    text-align: center;
    font-family: Mulish;
  }
  .btn_container {
    text-align: center;
  }
  .about_img_container {
    width: 100%;

    // margin-right: 0px;
    // margin-left: 0rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .about_img {
    max-width: 30rem;
  }
  .btn_box {
    padding: 1rem 0;
    text-align: center;
  }
  .social_container {
    height: 2rem;
    width: 2rem;
  }
  .social_img {
    height: 2rem;
    width: 2rem;
  }
}
@media screen and (max-width: 390px) {
  .overview_img {
    max-width: 40rem;
  }
  .inner_box {
    width: 80%;
  }
}
