@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");

html {
  // min-height: 100%; /* make sure it is at least as tall as the viewport */
}
html,
body {
  //min-height: 100%; /* force the BODY element to match the height of the HTML element */
  background-color: #ffffff;
  font-family: "Manrope"; //set default font
  padding: 0;
  margin: 0;
  overflow: hidden;
}

// * {
//   scrollbar-width: thin;
//   scrollbar-color: #e2e5f4 #f6f6f9;
// }

// /* Works on Chrome/Edge/Safari */
// *::-webkit-scrollbar {
//   width: 8px;
// }

// *::-webkit-scrollbar:horizontal {
//   width: 8px;
//   height: 9px !important;
// }

// *::-webkit-scrollbar-track {
//   background: #f6f6f9;
// }
// *::-webkit-scrollbar-thumb {
//   background-color: #e2e5f4;
//   border-radius: 4px;
//   border: 3px solid #f6f6f9;
// }

// /* Hide scrollbar for Chrome, Safari and Opera */
// .example::-webkit-scrollbar {
//   display: none;
// }

// /* Hide scrollbar for IE, Edge and Firefox */
// .example {
//   -ms-overflow-style: none; /* IE and Edge */
//   scrollbar-width: none; /* Firefox */
// }

.examiner_detail_wrapper input[type="text"] {
  /* Firefox, Chrome, Opera */
  padding-left: 10px;
  font-family: "Mulish";
  font-style: "normal";
  font-weight: 400;
  font-size: "15px";
  line-height: "22px";
  letter-spacing: "0.25px";
  color: "#6D6D6D";
  padding-top: "4px";
}

/* .tooltip { display: none;
  
   position: absolute; 
  margin-top: -50px;
  margin-left: 20px }
.tooltip-on-hover:hover + .tooltip { display: block;position: relative; } */
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  color: black;
  margin-top: -25px;
  margin-left: -25px;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.examiner_detail_wrapper input[type="password"] {
  /* Firefox, Chrome, Opera */
  padding-left: 10px;
}

.examiner_detail_wrapper input[type="tel"] {
  /* Firefox, Chrome, Opera */
  padding-left: 10px;
}

/* input[pattern]:invalid{
  color:red;
} */
span.MuiTypography-root.MuiListItemText-primary.MuiTypography-body1.MuiTypography-displayBlock {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #ffffff;
  font-family: "Mulish";
  text-align: left;
  transform: translateY(1px);
}

.spc {
  padding-left: 6px;
  padding-right: 6px;
}

.success_bar {
  text-align: center;
}

/* 
.editAction {
 margin-right: 50px;
} */

p.MuiTypography-root.MuiTypography-body1.MuiTypography-gutterBottom {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Mulish";
  padding-bottom: 60px;
}

.MuiCircularProgress-colorSecondary {
  color: #f50057;
  width: 20px !important;
  height: 20px !important;
}

.MuiListItem-gutters {
  padding-left: 40px !important;
}

.sidebar_button.MuiListItem-button {
  padding-bottom: 15px !important;
  padding-top: 15px !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  padding-left: 10px;
}

.side_button_wrapper {
  width: 90%;
  margin: 0 auto 0 auto;
}

.sidebar_button.MuiListItem-button:active,
.activeSelection {
  background-color: #d9001d;
  border-radius: 5px;
  transition: background-color 0.8s ease-out 0ms;
}

button.MuiButtonBase-root.MuiIconButton-root.makeStyles-topProfileAction-19 {
  background: none !important;
}

.notificationIcon .MuiSvgIcon-root {
  fill: #18273b !important;
  width: 1.2em;
  height: 1.25em;
  margin-right: 24px;
}

.sidebar_button .MuiListItemIcon-root {
  justify-content: flex-start;
  margin-left: -15px;
}

.sidebar_button .MuiListItemText-root {
  margin-left: -18px;
}

.profile_button .MuiButton-label {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  width: 200px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Mulish";
  text-transform: capitalize;
}

.button_invite_examiner button:hover {
  color: #ffffff;
  width: 184px;
  height: 48px;
  font-size: 18px;
  background: #d9001d;
  font-style: normal;
  text-align: center;
  font-family: Mulish;
  font-weight: bold;
  line-height: 20px;
  border-radius: 8px;
  letter-spacing: 0.15px;
  text-transform: capitalize;
}

.button_pop_up button:hover {
  color: #ffffff;

  font-size: 18px;
  background: #d9001d;
  font-style: normal;
  text-align: center;
  font-family: Mulish;
  font-weight: bold;
  line-height: 20px;
  border-radius: 4px;
  letter-spacing: 0.15px;
  text-transform: capitalize;
}

.button_candidate button:hover {
  color: #ffffff;
  width: 208px;
  height: 45px;
  font-size: 18px;
  background: #d9001d;
  font-style: normal;
  text-align: center;
  font-family: Mulish;
  font-weight: bold;
  line-height: 20px;
  border-radius: 4px;
  letter-spacing: 0.15px;
  text-transform: capitalize;
}

label+.WithStyles\(ForwardRef\(InputBase\)\)-root-53 {
  margin-top: 0 !important;
}

.examiner_detail_wrapper .MuiOutlinedInput-input {
  padding-top: 12px;
  padding-bottom: 10px;
}

.examiner_detail_wrapper .MuiInputBase-input {
  height: 10px;
}

.examiner_detail_wrapper .MuiNativeSelect-select.MuiNativeSelect-select {
  height: 25px !important;
  transform: translateY(-9px) !important;
}

svg.MuiSvgIcon-root.MuiNativeSelect-icon {
  transform: translateY(-14px);
}

.examiner_detail_wrapper svg.MuiSvgIcon-root.MuiNativeSelect-icon {
  transform: translateY(-10px);
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.MuiTableCell-root {
  padding: 12px !important;
}

tbody.MuiTableBody-root tr.MuiTableRow-root:hover {
  background: rgba(217, 0, 29, 0.06);
  cursor: pointer;
}

th.MuiTableCell-root.MuiTableCell-head {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15px;
  font-family: "Mulish";
  color: rgba(0, 0, 0, 0.45);
}

.selection_dept_invite {
  transform: translateY(19px);
}

.MuiButton-contained {
  box-shadow: unset !important;
}

th.MuiTableCell-root.MuiTableCell-body {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  font-family: "Mulish";
}

button.MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-page {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.15px;
  color: #000000;
  font-family: "Mulish";
}

.button_invite_examiner a {
  text-decoration: none;
}

.examiner_detail_wrapper .MuiFormControl-root {
  width: 90% !important;
}

select#demo-customized-select-native {
  width: 100%;
}

button.MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-page.Mui-selected {
  color: #ffffff;
  background: black;
}

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 20px;
  cursor: pointer;
}

/* Hide the default checkbox */
.container input {
  visibility: hidden;
  cursor: pointer;
}

/* Create a custom checkbox */
.mark {
  position: absolute;
  top: 7px;
  left: 0;
  height: 25px;
  width: 25px;
  border: 1px solid black;
  border-radius: 50px;
}

.container input:checked~.mark {
  background-color: #d9001d;
  border: none;
}

/* Create the mark/indicator (hidden when not checked) */
.mark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the mark when checked */
.container input:checked~.mark:after {
  display: block;
}

/* Style the mark/indicator */
.container .mark:after {
  left: 8px;
  top: 4px;
  width: 8px;
  height: 13px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.MuiTextField-root input {
  /* 14.5px = 18.5px - 4px (note: 18.5px is the input's default padding top and bottom) */
  padding-top: 14.5px;
  padding-bottom: 14.5px;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.15px;
}

.MuiOutlinedInput-root .MuiSelect-outlined.MuiSelect-outlined {
  padding: 16px 32px 16px 14px !important;
}

.MuiTextField-root label {
  top: -4px;
}

.MuiTextField-root label[data-shrink="true"] {
  top: 0;
}

.candidate_dropdown {
  margin-left: -14px;
}

.candidate_dropdown fieldset {
  border: none !important;
  outline: none !important;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

/* .loader_animation{
  background:black;
  position: relative;
  animation: mymove 5s infinite;
  height:50px;
  width:50px;
}
@keyframes mymove {
  from {bottom: -50px;}
  to {bottom: 0px;}
} */

/* Select Dropdown */
.MuiSelect-icon {
  color: #000000 !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #d9001d !important;
}

/* Tab Panel */
.MuiAppBar-colorPrimary {
  background-color: white !important;
}

.MuiTab-wrapper {
  color: #000000 !important;
}

.form-group {
  display: block;
  /* margin-bottom: 15px; */
  align-items: center;
  justify-content: center;
}

.form-group input {
  padding: 0;
  height: initial;
  width: 10px;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group label {
  position: relative;
  cursor: pointer;
}

.form-group label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #383838;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 7px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 12px;
}

.popoverMenuItem {
  min-height: 3rem !important;
  font-size: 16px;
  font-weight: 600;

}

.form-group input:checked+label:after {
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  left: 6px;
  width: 4px;
  height: 10px;
  border: solid #383838;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.loader {
  border: 1px solid #f3f3f3;
  border-radius: 50%;
  border-top: 1px solid #00d923;
  width: 24px;
  height: 24px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

.menuItem:hover {
  backgroundColor: "#009900 !important"
}

@media print {
  .pageBreak {
    margin-top: 180px;
  }

  .pagePrintWrapper {
    padding: 50px;
  }

  .noBorder {
    border: none !important;
    width: 100%;
  }

  .displaynone {
    display: none !important;
  }
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}