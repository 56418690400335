/* ---Sidebar css */
.text-primary {
  color: #5a8dee !important;
}
.btn-primary {
  border-color: #5a8dee !important;
  background-color: #5a8dee !important;
  color: #fff;
}
.shadow-md {
  /* box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3); */
}
.navbar {
  border-radius: 5px;
}
.sidebar {
  padding: 0px 0px;
  box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
  background-color: #383838;
  overflow-y: scroll;
  z-index: 105;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 0.5s;
}
.sidebar.active {
  left: 0;
}
.sd-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
}
.sidebar-overlay {
  position: fixed;
  z-index: 104;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}
.sidebar-overlay.active {
  opacity: 1;
  visibility: visible;
}
.sd-body {
  padding: 10px 15px;
  /* max-height: calc(100vh - 67px); */
  overflow-x: hidden;
  overflow-y: scroll;
}
.sd-body ul {
  display: inline-block;
  width: 100%;
  margin-bottom: 0;
  padding: 0;
}
.sd-body ul li {
  list-style: none;
  margin-bottom: 8px;
}
.sd-body ul li .sd-link {
  display: inline-block;
  width: 100%;
  padding: 10px 16px;
  color: #475f7b;
  background-color: #e5e8ec;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
}

.hamburger-menu {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  width: 1.5rem;
  height: 1.3rem;
  z-index: 110;
  cursor: pointer;
}
.bar-top,
.bar-middle,
.bar-bottom {
  height: 4px;
  background: #383838;
  border-radius: 5px;
  margin: 3px 0;
  transform-origin: left;
  transition: all 0.5s;
}
.hamburger-menu-hover .bar-top {
  transform: rotate(45deg);
}
.hamburger-menu-hover .bar-middle {
  transform: translateX(1rem);
  opacity: 0;
}
.hamburger-menu-hover .bar-bottom {
  transform: rotate(-45deg);
}
