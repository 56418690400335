.filter_popup_label {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  width: 100%;
  margin-left: -1.2rem;
  display: block;
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  
}

.filter_popup_checkbox {
  justify-content: space-between !important;
  margin-top: -2%;
  width: 100%;
}
.select_container {
  width: 100%;
  height: 2rem;
  margin-top: 1.5rem;
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #383838;
}
.select_item {
  font-weight: 400 !important;
  font-size: 12px !important;
  color: #383838;
  height: 2.5rem;

}
.menu_container {
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  max-height: 52vh;
  overflow: scroll;
}
